import { memo } from 'react'
import { useSearchParams } from 'next/navigation'
import { ContentstackPageHeader } from '@Types/contentstack'
import { ProductListingPage } from 'composable/components/plp/product-listing-page'
interface PLPTasticProps {
  data: {
    data: {
      dataSource: {
        query: string
        isSearchPage: boolean
        category?: {
          custom?: {
            fields?: { h1?: string }
          }
        }
        pageHeaderEntry?: ContentstackPageHeader
        articleCardSliderEntry?: ContentstackPageHeader
      }
    }
  }
}
const PLPTastic = memo(({ data }: PLPTasticProps) => {
  const searchParams = useSearchParams()

  const query = searchParams.get('query') || data.data.dataSource.query

  return (
    <ProductListingPage
      category={data.data.dataSource.category}
      query={query}
      isSearchPage={data.data.dataSource.isSearchPage}
      pageHeaderEntry={data.data.dataSource.pageHeaderEntry}
      articleCardSliderEntry={data.data.dataSource.articleCardSliderEntry}
    />
  )
})

PLPTastic.displayName = 'PLPTastic'

export default PLPTastic
