import { createRef, FunctionComponent } from 'react'
import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { useInstantSearchLoadingState } from 'helpers/hooks/useInstantSearchLoadingState'
import { UseInfiniteHitsProps, useInfiniteHits } from 'react-instantsearch'
import { StarterKitAlgoliaProduct } from '../../types'

type ItemCountProps = {
  flexProps?: FlexProps
  infiniteHitsProps: UseInfiniteHitsProps<StarterKitAlgoliaProduct>
}

export const refItemCount = createRef<HTMLDivElement>()

export const ItemCount: FunctionComponent<ItemCountProps> = ({ infiniteHitsProps, flexProps }) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { results } = useInfiniteHits(infiniteHitsProps)

  const { isLoading } = useInstantSearchLoadingState()

  const sortedHits = results.nbSortedHits || results.nbHits

  return (
    <Flex ref={refItemCount} tabIndex={0} direction="column" {...flexProps}>
      <Text fontSize={{ base: 'sm' }} fontWeight="normal">
        {formatMessage({
          id: 'category.results.displaying',
        })}
      </Text>

      <Text fontSize={{ base: 'md' }} fontWeight="normal" color="text-primary">
        {formatMessage({
          id: 'category.results.itemCount',
          values: { itemCount: sortedHits },
        })}
      </Text>
    </Flex>
  )
}
