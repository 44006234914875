import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FiltersIcon } from 'composable/components/icons/filters'
import { PersistentModal } from 'composable/components/persistent-modal'
import { useFormat } from 'helpers/hooks/useFormat'
import { useCurrentRefinements, useClearRefinements, UseInfiniteHitsProps, useInfiniteHits } from 'react-instantsearch'
import { Filters } from './filters'
import { StarterKitAlgoliaProduct } from '../../types'

type FilersModalProps = {
  infiniteHitsProps: UseInfiniteHitsProps<StarterKitAlgoliaProduct>
}

export const FiltersModal = ({ infiniteHitsProps }: FilersModalProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { formatMessage } = useFormat({ name: 'common' })

  const { items } = useCurrentRefinements()
  const { refine: clearAllRefinements } = useClearRefinements()

  const currentRefinementsCount = items.reduce(
    (prev, refinementAttribute) => prev + refinementAttribute.refinements.length,
    0,
  )

  const { results } = useInfiniteHits(infiniteHitsProps)

  return (
    <>
      <Text as="label" htmlFor="btnMmobileFilters" fontSize="sm">
        {formatMessage({ id: 'category.filters.refineBy' })}
      </Text>
      <Button
        id="btnMmobileFilters"
        mt={1}
        variant="outline-black"
        onClick={() => onOpen()}
        rightIcon={<FiltersIcon ml="auto" />}
        iconSpacing="auto"
      >
        <Text fontWeight="normal" fontSize="base">
          {formatMessage({ id: 'category.filters.filters' })} {`(${currentRefinementsCount})`}
        </Text>
      </Button>

      <PersistentModal isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent m={0} rounded={0}>
          <ModalHeader position="sticky" top={0} zIndex={2} bg="surface.primary">
            <ModalCloseButton onClick={onClose} />
            {formatMessage({ id: 'category.filters.refineBy' })}
          </ModalHeader>

          <ModalBody>
            <Filters infiniteHitsProps={infiniteHitsProps} />
          </ModalBody>

          <ModalFooter gap={2} position="sticky" bottom={0} zIndex={2} bg="surface.primary">
            <Button variant="outline" onClick={clearAllRefinements}>
              {formatMessage({ id: 'category.filters.action.clear' })}
            </Button>
            <Button colorScheme="blue" onClick={onClose}>
              {formatMessage({ id: 'category.results.view' })}
              <Text fontWeight="normal">{results?.nbHits}</Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </PersistentModal>
    </>
  )
}
