import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from '../../../../helpers/hooks/useSessionStorage/sessionStorageUtils'

export const getIsBrowser = () => {
  return typeof window !== 'undefined' && typeof window.document !== 'undefined'
}

export const getCurrentUrl = () => {
  return typeof window !== 'undefined' && window.location
}

const isPrimitive = (obj) => {
  return obj !== Object(obj)
}

const isEqual = (first, second) => {
  if (first === second) {
    return true
  }
  if (isPrimitive(first) || isPrimitive(second) || typeof first === 'function' || typeof second === 'function') {
    return first === second
  }
  if (Object.keys(first).length !== Object.keys(second).length && Object.keys(second).length !== 0) {
    return false
  }

  for (var _i = 0, _Object$keys = Object.keys(first); _i < _Object$keys.length; _i++) {
    var key = _Object$keys[_i]
    if (!(key in second)) {
      return false
    }
    if (!isEqual(first[key], second[key])) {
      return false
    }
  }
  return true
}

export const SESSION_STORAGE_KEY = 'custom.ais.infiniteHits'
let _excluded = ['page']

const _objectWithoutProperties = (source, excluded) => {
  if (source == null) return {}
  let target = _objectWithoutPropertiesLoose(source, excluded)
  let key, i
  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source)
    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i]
      if (excluded.indexOf(key) >= 0) continue
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue
      target[key] = source[key]
    }
  }
  return target
}

const _objectWithoutPropertiesLoose = (source, excluded) => {
  if (source == null) return {}
  let target = {}
  let sourceKeys = Object.keys(source)
  let key, i
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i]
    if (excluded.indexOf(key) >= 0) continue
    target[key] = source[key]
  }
  return target
}

const getStateWithoutPage = (state) => {
  let _ref = state || {},
    page = _ref.page,
    rest = _objectWithoutProperties(_ref, _excluded)
  return rest
}

/**
 * Merges the new hits with the existing cached hits while ensuring outdated data is removed.
 *
 * Why this method exists:
 * - When navigating back to an earlier page or applying new filters,
 *   the `newHits` will contain only the most relevant page(s).
 * - We remove any keys in `oldHits` that are beyond the highest page in `newHits`
 *   to avoid keeping stale or irrelevant data in the cache.
 * - This ensures the cache stays clean and only contains the most accurate data.
 *
 * @param {Object} oldHits - The previously cached hits, keyed by page number.
 * @param {Object} newHits - The latest hits from the server, keyed by page number.
 * @returns {Object} - The updated cache, containing only relevant hits.
 */
const mergeHits = (oldHits, newHits) => {
  // Get the highest page key from newHits
  const newPageKey = Math.max(...Object.keys(newHits).map((key) => parseInt(key, 10)))

  // Filter out keys from oldHits that are greater than the highest key in newHits
  const filteredOldHits = Object.fromEntries(Object.entries(oldHits).filter(([key]) => parseInt(key, 10) <= newPageKey))

  // Merge the filtered old hits with the new hits
  return { ...filteredOldHits, ...newHits }
}

export const createInfiniteHitsSessionStorageCustomCache = (allowedPathnames = []) => {
  return {
    read({ state }) {
      if (!getIsBrowser()) {
        return null
      }
      const cache = getSessionStorageItem(SESSION_STORAGE_KEY, '')
      if (!cache) {
        return null
      }
      try {
        let parsedCache = JSON.parse(cache)
        const isInitialized =
          state?.disjunctiveFacets?.length > 0 && Object.keys(state?.disjunctiveFacetsRefinements).length > 0
        return (cache && isEqual(parsedCache.state, getStateWithoutPage(state))) || !isInitialized
          ? parsedCache.hits
          : null
      } catch (error) {
        if (error instanceof SyntaxError) {
          try {
            removeSessionStorageItem(SESSION_STORAGE_KEY)
          } catch (err) {
            console.error('Error in createInfiniteHitsSessionStorageCustomCache', err)
          }
        }
        return null
      }
    },
    write({ state, hits }) {
      const location = getCurrentUrl()
      const basePathname = location?.pathname?.split('/').length > 0 ? location?.pathname?.split('/')[1] : ''
      if (!getIsBrowser() || !allowedPathnames.includes(basePathname)) {
        return null
      }

      if (!sessionStorage) {
        return
      }

      const cache = getSessionStorageItem(SESSION_STORAGE_KEY, '')
      let parsedCache = { hits: {} }

      if (cache) {
        try {
          parsedCache = JSON.parse(cache)
        } catch (error) {}
      }

      setSessionStorageItem(
        SESSION_STORAGE_KEY,
        JSON.stringify({
          state: getStateWithoutPage(state),
          hits: mergeHits(parsedCache.hits, hits),
        }),
      )
    },
  }
}
