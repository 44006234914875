import { Configure } from 'react-instantsearch'
import { SALE_DISCOUNT_AMOUNT_THRESHOLD, SALE_SLUG } from '../../../general/constants'
import { CATEGORY_SEARCH_FIELDS, DEFAULT_SHOW_MORE_REFINEMENTS_LIMIT } from '../../constants'
import { useGridLayout } from '../../hooks/use-grid-layout'
import { NEW_ARRIVAL_SLUG, NEW_ARRIVAL_STAMP } from 'composable/components/general/constants'

interface AlgoliaConfigurationProps {
  query: string
  isSearchPage?: boolean
}

export const AlgoliaConfiguration = (props: AlgoliaConfigurationProps) => {
  const { query, isSearchPage = false } = props
  const { hitsPerPage } = useGridLayout()

  const getFilterProps = () => {
    if (isSearchPage) {
      return { query }
    }

    if (query === NEW_ARRIVAL_SLUG) {
      return { filters: `actualGoLiveDate >= ${NEW_ARRIVAL_STAMP}` }
    }

    if (query === SALE_SLUG) {
      return { filters: `discountAmount >= ${SALE_DISCOUNT_AMOUNT_THRESHOLD}` }
    }

    return { filters: `categories: ${query}` }
  }

  return (
    <Configure
      clickAnalytics={true}
      hitsPerPage={hitsPerPage}
      restrictSearchableAttributes={isSearchPage ? [] : CATEGORY_SEARCH_FIELDS}
      facetingAfterDistinct
      maxValuesPerFacet={DEFAULT_SHOW_MORE_REFINEMENTS_LIMIT}
      {...getFilterProps()}
    />
  )
}
