import { FunctionComponent, memo } from 'react'
import { Heading } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'

type TitleProps = {
  categoryH1?: string
  isLoading: boolean
  isSearchPage: boolean
  query: string
}

export const Title: FunctionComponent<TitleProps> = memo(({ categoryH1, isLoading, isSearchPage, query }) => {
  const { formatMessage } = useFormat({ name: 'common' })

  if (isSearchPage) {
    if (query) {
      return (
        <Heading tabIndex={0} as="h1" fontSize={{ base: '6xl', lg: '7xl' }} fontWeight={700} textTransform="none">
          {formatMessage({
            id: 'category.search.resultsFor',
            values: { query },
          })}
        </Heading>
      )
    }

    return null
  }

  if (categoryH1) {
    return (
      <Heading tabIndex={0} as="h1" fontSize={{ base: '6xl', lg: '7xl' }} fontWeight={700} textTransform="capitalize">
        {formatMessage({ id: 'category.search.shop', values: { query: categoryH1 } })}
      </Heading>
    )
  }

  return null
})

Title.displayName = 'Title'
