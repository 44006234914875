import React, { useEffect } from 'react'
import { Box, HStack } from '@chakra-ui/react'
import { POWERREVIEWS_API_KEY, POWERREVIEWS_MERCHANT_GROUP_ID, POWERREVIEWS_MERCHANT_ID } from '../general/constants'

export const PrRatings = (props) => {
  useEffect(() => {
    getPrReviewsData()
  }, [])

  const getPrReviewsData = () => {
    let itemsArray = null
    ;(window as any).pwr =
      (window as any).pwr ||
      function () {
        ;((window as any).pwr.q = (window as any).pwr.q || []).push(arguments)
      }

    if (props.hitss?.isBanner !== true) {
      const obj = {
        locale: 'en_US',
        ENABLE_CLIENT_SIDE_STRUCTURED_DATA: false,
        merchant_group_id: POWERREVIEWS_MERCHANT_GROUP_ID,
        page_id: '',
        merchant_id: POWERREVIEWS_MERCHANT_ID,
        api_key: POWERREVIEWS_API_KEY,
        components: {
          CategorySnippet: '',
        },
      }
      obj.page_id = props.hitss.key
      obj.components.CategorySnippet = 'category-snippet-' + props.index
      itemsArray = obj
    }

    const prReviewsInterval = setInterval(() => {
      if (document.getElementById('category-snippet-0')) {
        clearInterval(prReviewsInterval)
        ;(window as any).pwr('render', itemsArray)
        setTimeout(() => {
          formatReviewCount()
        }, 200)
      }
    }, 200)
  }

  const formatReviewCount = () => {
    if (props.hitss !== true) {
      const reviewsCountCheckInterval = setInterval(() => {
        const categorySnippet = document.querySelector(`#category-snippet-${props.index}`)
        if (categorySnippet) {
          const reviewCountElem = categorySnippet.querySelector('.pr-category-snippet__total')
          if (reviewCountElem) {
            clearInterval(reviewsCountCheckInterval)
            const rCount = parseInt((reviewCountElem as any).innerText, 10) || 0

            // Update the review count text
            const reviewCountElement = document.querySelector('#review-count-' + props.index)
            if (reviewCountElement) {
              ;(reviewCountElement as HTMLElement).innerText = `(${rCount})`

              // Update the aria-label
              reviewCountElement.setAttribute('aria-label', `${rCount} people have rated it`)
            }
          }
        }
      }, 500)
    }
  }

  return (
    <HStack tabIndex={0} id="prRating" display={'Flex'} spacing={2} h="21px">
      <Box id={'category-snippet-' + props.index}> </Box>
      <Box
        textStyle={'body-75'}
        marginBottom={'-4px'}
        id={'review-count-' + props.index}
        aria-label="Loading review count..."
      ></Box>
    </HStack>
  )
}
